.timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: -50px; /* Adjust to align the line with the badge */
    width: 2px;
    height: 100%;
    background: #eaeaea;
}

.timeline {
    position: relative;
}
.timeline-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    left: 50px;
    padding-bottom: 20px; /* Adjust this value based on your design */
}
